import { types } from 'mobx-state-tree';

const status = {
  LOADING: 'LOADING',
  LOADED: 'LOADED',
  ERROR: 'ERROR',
};

export const RequestStatus = types
  .model('RequestStatus', {
    status: status.LOADED,
    message: types.maybeNull(types.string),
  })
  .views(self => ({
    get isLoading() {
      return self.status === status.LOADING;
    },

    get isLoaded() {
      return self.status === status.LOADED;
    },

    get isError() {
      return self.status === status.ERROR;
    },
  }))
  .actions(self => ({
    loading() {
      self.status = status.LOADING;
    },
    loaded() {
      self.status = status.LOADED;
    },
    error(message) {
      self.status = status.ERROR;
      self.message = message;
    },
  }));
