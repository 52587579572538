import React from 'react';
import PropTypes from 'prop-types';

function Logo({ logo, ...props }) {
  if (!logo) {
    return <div className="logo logo--empty" {...props} />;
  }

  return (
    <div className="logo" {...props}>
      <img className="img-fluid" src={`data:image/png;base64,${logo}`} alt="logo" />
    </div>
  );
}

Logo.propTypes = {
  logo: PropTypes.string,
};

Logo.defaultProps = {
  logo: null,
};

export default Logo;
