import { types } from 'mobx-state-tree';
import { formatDate } from '@/helpers';

export default types
  .model('SetDetails', {
    Aantal_vragen: '',
    Bedrijf: '',
    Deadline: '',
    ID_Event: '',
    ID_Event_handler: '',
    ID_set_uuid: '',
    Ids_vragen: '',
    Logo_base: '',
    Omschrijving: '',
    Send_date: '',
    Set_status: '',
    Steller: '',
    Titel: '',
    recordID: 0,
  })
  .views(self => ({
    get hasResults() {
      return self.Set_status.toUpperCase() === 'RESULTS';
    },

    get hasConclusion() {
      return self.Set_status.toUpperCase() === 'CONCLUSIE';
    },

    get isCompleted() {
      return self.hasResults || self.hasConclusion;
    },

    get formatedSendDate() {
      return formatDate(self.Send_date);
    },

    get formatedDeadline() {
      return formatDate(self.Deadline);
    },
  }));
