import React from 'react';
import PropTypes from 'prop-types';
import Logo from './Logo';

const Header = ({ logo, headline, title, children }) => {
  return (
    <section id="header" className="header">
      <div className="container">
        <div className="row">
          <div className="col-lg-auto col-md-12 header__logo-wrapper">
            <Logo logo={logo} />
          </div>
          <div className="col header__title">
            <h6 className="purple">{title}</h6>
            {headline && <h4 className="semibold">{headline}</h4>}
            {children}
          </div>
        </div>
      </div>
    </section>
  );
};

Header.propTypes = {
  logo: PropTypes.string,
  headline: PropTypes.string,
  title: PropTypes.string,
  children: PropTypes.node,
};

Header.defaultProps = {
  title: null,
  headline: null,
  logo: null,
  children: null,
};

export default Header;
