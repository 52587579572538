import React from 'react';
import PropTypes from 'prop-types';
import LoadingIndicator from './LoadingIndicator';
import Header from './Header';

const notFoundImage = require('@src/img/error_404.png');

function LoadingScreen({ message, isLoading }) {
  let loadingElement;
  if (isLoading) {
    loadingElement = <LoadingIndicator />;
  } else if (!isLoading && message) {
    // eslint-disable-next-line react/no-danger
    loadingElement = <p className="text-center" dangerouslySetInnerHTML={{ __html: message }} />;
  } else {
    loadingElement = <img className="img-fluid" src={notFoundImage} alt="404" />;
  }

  return (
    <React.Fragment>
      <Header />
      <section id="body">
        <div className="container">
          <div className="row white-block paddingmedium content">
            <div className="col-12">{loadingElement}</div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

LoadingScreen.propTypes = {
  message: PropTypes.string,
  isLoading: PropTypes.bool,
};

LoadingScreen.defaultProps = {
  message: 'Sorry, er is iets waanzinnig <br/> fout gegaan...',
  isLoading: true,
};

export default LoadingScreen;
