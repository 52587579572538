import * as React from 'react';

const imgBrand = require('@src/img/Brand.svg');

function Footer() {
  return (
    <section id="footer">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <img src={imgBrand} alt="Rocket Engine Logo" />
            <p className="small muted">
              Loggix BV gebruikt Rocket Engine om met je in contact te blijven en van jou te leren.
              Het is belangrijk om te weten dat jouw antwoorden 100% anoniem worden verwerkt.{' '}
              <a href="https://www.loggix.com/" target="_blank" rel="noopener noreferrer">
                Lees hier meer.
              </a>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Footer;
