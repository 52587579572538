import { types, flow } from 'mobx-state-tree';
import client from '@/webservices/client';
import { Result, parseData } from './Result';
import Question from '../Question';
import SetDetails from '../SetDetails';

const QuestionResult = types.model('QuestionResult', {
  question: types.reference(Question),
  result: types.maybe(types.reference(Result)),
});

export default types
  .model('ResultStore', {
    conclusion: types.maybe(types.string),
    // Refs
    results: types.array(Result),
    questions: types.array(Question),
    setDetails: types.maybe(SetDetails),
    questionResults: types.array(QuestionResult),
  })
  .views(self => ({
    get numberOfQuestions() {
      return self.questionResults.length;
    },
  }))
  .volatile(() => ({
    loading: true,
    error: null,
  }))
  .actions(self => {
    const loadData = flow(function*(uuid) {
      if (!uuid) {
        self.error = '404';
        return;
      }

      self.loading = true;
      self.error = null;
      try {
        const { data } = yield client.get(`/api/question-set/${uuid}/results`);

        self.questions = data.questions.map(q => q.fieldData);
        self.results = data.portalData.Results_all.map(parseData);
        self.setDetails = data.fieldData;

        self.questionResults = self.questions.map(q => ({
          question: q.id_vraag,
          result: self.results.find(r => r.question.id_vraag === q.id_vraag),
        }));

        self.conclusion = data.fieldData['Event_handlers::Conclusie_management'];
      } catch (e) {
        self.error = e;
      }
      self.loading = false;
    });

    return {
      loadData,
    };
  });
