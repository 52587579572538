import * as React from 'react';
import ScaleLoader from 'react-spinners/ScaleLoader';

const defaultColor = '#FF742E';
const LoadingIndicator = ({ color }) => {
  return (
    <div className="text-center mt-2">
      <ScaleLoader color={color || defaultColor} />
    </div>
  );
};

export default LoadingIndicator;
