import { types } from 'mobx-state-tree';

export const File = types.model({
  data: types.string,
  name: types.string,
  id: types.identifier,
});

export function parseData(data) {
  return {
    data: data['Event_bijlages::Bijlage_base'],
    name: data['Event_bijlages::Titel_bijlage'],
    id: data.recordId,
  };
}
