import { types, flow, getEnv } from 'mobx-state-tree';
import client from '../webservices/client';
import { RequestStatus } from './RequestStatus';
import { SubscriptionDetails } from './SubscriptionDetails';

export const Subscription = types
  .model('Subscription', {
    details: types.optional(SubscriptionDetails, {}),
    rqSubscriptionStatus: types.optional(RequestStatus, {}),
    rqSubmitStatus: types.optional(RequestStatus, {}),
    title: '',
    message: '',
    notes: '',
    recordId: '',
    userIP: '',
  })
  .volatile(() => ({
    isShowInfo: false,
    isShowErrorMessage: false,
  }))
  .views(self => ({
    get navigator() {
      return getEnv(self).navigator;
    },

    get isLoading() {
      return self.rqSubscriptionStatus.isLoading;
    },

    get isError() {
      return self.rqSubscriptionStatus.isError;
    },

    get errorMessage() {
      return self.rqSubscriptionStatus.message;
    },

    get isSubmitting() {
      return self.rqSubmitStatus.isLoading;
    },
  }))
  .actions(self => ({
    setIsShowErrorMessage(val) {
      self.isShowErrorMessage = val;
    },

    setData(data) {
      self.title = data.title;
      self.message = data.message;
      self.notes = data.notes;
      self.recordId = data.recordId;
      self.userIP = data.userIP;
    },

    loadSubscriptionDetails: flow(function*(uuid) {
      if (!uuid) {
        self.rqSubscriptionStatus.error();
        return;
      }

      self.rqSubscriptionStatus.loading();
      try {
        const res = yield client.get(`/api/users/${uuid}/subscription-status`);
        self.details = res.data.fieldData;
        self.setData(res.data);
        self.rqSubscriptionStatus.loaded();
      } catch (e) {
        self.rqSubscriptionStatus.error();
      }
    }),

    optIn: flow(function*() {
      self.rqSubmitStatus.loading();

      try {
        const res = yield client.post(`/api/users/${self.recordId}/opt-in`);
        self.didOptIn();
        self.setData(res.data);
        self.rqSubmitStatus.loaded();
        self.navigator.push('/optin-success');
      } catch (e) {
        self.rqSubmitStatus.error();
        self.isShowErrorMessage = true;
      }
    }),

    optOut: flow(function*() {
      self.rqSubmitStatus.loading();

      try {
        const res = yield client.post(`/api/users/${self.recordId}/opt-out`);
        self.didOptOut();
        self.setData(res.data);
        self.rqSubmitStatus.loaded();
        self.navigator.push('/optin-success');
      } catch (e) {
        self.rqSubmitStatus.error();
        self.isShowErrorMessage = true;
      }
    }),

    didOptIn() {
      self.details.setOptStatus('1');
    },

    didOptOut() {
      self.details.setOptStatus('0');
    },

    setIsShowInfo(val) {
      self.isShowInfo = val;
    },
  }));
