import { flow, types } from 'mobx-state-tree';
import client from '@/webservices/client';
import { File, parseData } from './File';
import Question from './Question';
import { RequestStatus } from './RequestStatus';
import SetDetails from './SetDetails';

export const SetStatus = Object.freeze({
  NEW: 'NEW',
  STARTED: 'STARTED',
  FINISHED: 'FINISHED',
});

export const Set = types
  .model('Set', {
    loadingStatus: types.optional(RequestStatus, {}),
    submittingStatus: types.optional(RequestStatus, {}),
    details: types.optional(SetDetails, {}),
    questions: types.array(Question),
    files: types.array(File),
  })
  .volatile(() => ({
    status: SetStatus.NEW,
    step: 0,
    isConfirming: false,
    isShowErrorMessage: false,
  }))
  .views(self => ({
    get canGoNext() {
      return self.step < self.questions.length;
    },

    get canGoBack() {
      return self.step > 0;
    },

    get currentQuestion() {
      if (self.questions.length <= 0) {
        return null;
      }

      return self.questions[self.step];
    },

    get totalQuestions() {
      return self.questions.length;
    },

    get isLoading() {
      return self.loadingStatus.isLoading;
    },

    get isError() {
      return self.loadingStatus.isError;
    },

    get errorMessage() {
      return self.loadingStatus.message;
    },

    get progress() {
      return self.step + 1;
    },

    get isTheLastQuestion() {
      return self.progress >= self.totalQuestions;
    },

    get isSubmitting() {
      return self.submittingStatus.isLoading;
    },

    get totalAnsweredQuestions() {
      return self.questions.filter(item => item.answered).length;
    },

    get remainingQuestions() {
      return self.totalQuestions - self.totalAnsweredQuestions;
    },
  }))
  .actions(self => ({
    setQuestions(questions) {
      self.questions = questions.map(item => item.fieldData);
    },

    updateCurrentQuestion(questions) {
      const question = questions
        .map(item => item.fieldData)
        .find(q => q.recordID === self.currentQuestion.recordID);

      if (!question) {
        return;
      }
      self.questions[self.step] = question;
    },

    setStep(val) {
      self.step = val;
      self.currentQuestion.setOpened();
    },

    setIsShowErrorMessage(val) {
      self.isShowErrorMessage = val;
    },

    goBack() {
      if (self.canGoBack) {
        self.setStep(self.step - 1);
      }
    },

    setStatus(val) {
      self.status = val;
      if (val === SetStatus.STARTED) {
        self.currentQuestion.setOpened();
      }
    },

    setIsConfirming(val) {
      self.isConfirming = val;
    },

    fetchQuestionSet: flow(function*(uuid) {
      if (!uuid) {
        self.rqSubscriptionStatus.error();
        return;
      }

      self.loadingStatus.loading();
      try {
        const res = yield client.get(`/api/question-set/${uuid}`);

        self.details = res.data.fieldData;
        self.setQuestions(res.data.questions);
        self.loadingStatus.loaded();
        self.files = res.data.portalData.portal_bijlages.map(parseData);

        if (self.details.isCompleted) {
          self.status = SetStatus.FINISHED;
        }
      } catch (e) {
        self.loadingStatus.error();
      }
    }),

    submitAnswer: flow(function*(recordId, answer) {
      self.submittingStatus.loading();
      let postData = { answer };
      const axiosConfig = {};
      const { currentQuestion } = self;

      try {
        if (currentQuestion.type === 'upload') {
          postData.sender = currentQuestion.Afzender;

          // Submitting a new file
          if (currentQuestion.file) {
            postData.answer = 'uploaded';
            postData.file = currentQuestion.file;
            const formData = new FormData();

            Object.keys(postData).forEach(k => {
              formData.append(k, postData[k]);
            });

            // Switch to file upload
            postData = formData;
            axiosConfig.headers = {
              'Content-Type': 'multipart/form-data',
            };
          } else if (
            // Delete existing file
            currentQuestion.originalFileContent !== currentQuestion.container_base
            && currentQuestion.container_base === ''
          ) {
            postData.answer = '';
            postData.container = '';
            postData.fileName = '';
          }
        }

        const response = yield client.post(
          `/api/set/${self.details.ID_set_uuid}/questions/${recordId}`,
          postData,
          axiosConfig,
        );

        self.updateCurrentQuestion(response.data.questions);
        // self.setQuestions(res.data.questions);
        currentQuestion.setSubmitted();
        self.submittingStatus.loaded();
        return true;
      } catch (e) {
        self.submittingStatus.error();
        self.isShowErrorMessage = true;
      }

      return false;
    }),

    goNext: flow(function*() {
      if (!self.currentQuestion.validate()) {
        return false;
      }

      const { recordID, Antwoord, isDirty } = self.currentQuestion;
      let success = true;
      if (isDirty) {
        success = yield self.submitAnswer(recordID, Antwoord);
      }

      if (!success) {
        return false;
      }

      if (self.isTheLastQuestion) {
        return self.setIsConfirming(true);
      }

      if (self.canGoNext) {
        self.setStep(self.step + 1);
      }
      return true;
    }),

    submitSetAsCompleted: flow(function*() {
      self.submittingStatus.loading();

      try {
        yield client.post(`/api/question-set/${self.details.ID_set_uuid}/complete`);
        self.submittingStatus.loaded();
        self.isConfirming = false;
        self.status = SetStatus.FINISHED;
      } catch (e) {
        self.submittingStatus.error();
      }
    }),
  }));
