import { types } from 'mobx-state-tree';

export const SubscriptionDetails = types
  .model('SubscriptionDetails', {
    id: '',
    Voornaam: '',
    Email: '',
    Achternaam: '',
    Aanhef: '',
    opt_timestamp: '',
    opt_status: '0',
    opt_ip: '',
    'Bedrijf::Bedrijfsnaam': '',
    'Bedrijf::Logo_base': '',
    'Bedrijf::Optin_text': '',
    recordId: '',
  })
  .views(self => ({
    get isOptIn() {
      return self.opt_status === '1';
    },
  }))
  .actions(self => ({
    setOptStatus(val) {
      self.opt_status = val;
    },
  }));
