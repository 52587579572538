import parse from 'date-fns/parse';
import format from 'date-fns/format';

export const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('');

export const formatDate = date => {
  try {
    return format(parse(date, 'MM/dd/yyyy', new Date()), 'dd/MM/yyyy');
  } catch (e) {
    return date;
  }
};
