import { types, flow } from 'mobx-state-tree';
import client from '@/webservices/client';

const Answer = types.union(types.array(types.string), types.string);

export const ValidateStatus = Object.freeze({
  PENDING: 'PENDING',
  OK: 'OK',
  FAILED: 'FAILED',
});

const Question = types
  .model('Question', {
    recordID: types.maybeNull(types.integer),
    Afzender: '',
    Antwoord: types.maybeNull(Answer),
    Bedrijf: '',
    File_naam: '',
    Geopend: types.maybeNull(types.integer),
    Submit: types.maybeNull(types.integer),
    Respons: types.maybeNull(types.integer),
    ID_set_uuid: '',
    Logo_base: '',
    'Mail::Set_status': '',
    Opties: types.array(types.string),
    Set_status: '',
    Vraag: '',
    Vraag_Type: '',
    container_base: '',
    id_vraag: types.identifier,
    verplicht_afzender: types.union(types.string, types.number),
  })
  .volatile(() => ({
    originalAnwser: null,
    originalSender: null,
    file: null,
    validateStatus: ValidateStatus.PENDING,
  }))
  .views(self => ({
    get type() {
      return self.Vraag_Type.toLowerCase();
    },

    get requireSender() {
      return self.verplicht_afzender === 1;
    },

    get multipleChoicesAnswer() {
      return self.Antwoord.slice()
        .sort()
        .join('|');
    },

    get fileURL() {
      if (!self.file) {
        return null;
      }
      return URL.createObjectURL(self.file);
    },

    get answered() {
      switch (self.type) {
        // File upload
        case 'upload': {
          return !!self.file || !!self.Afzender || !!self.container_base;
        }
        // Multiple choices
        case 'meerkeuze': {
          return self.Antwoord.length > 0;
        }
        // Other question type
        default: {
          return !!self.Antwoord;
        }
      }
    },

    get isDirty() {
      switch (self.type) {
        // File upload
        case 'upload': {
          if (
            self.file !== null
            || self.originalSender !== self.Afzender
            || self.originalFileContent !== self.container_base
          ) {
            return true;
          }
          break;
        }
        // Multiple choices
        case 'meerkeuze': {
          if (self.originalAnwser !== self.multipleChoicesAnswer) {
            return true;
          }
          break;
        }
        // Other question type
        default: {
          if (self.originalAnwser !== self.Antwoord) {
            return true;
          }
        }
      }
      return false;
    },
  }))
  .actions(self => {
    // Validate if the question can be submitted
    const validate = () => {
      switch (self.type) {
        // File upload
        case 'upload': {
          // The name is required when uploading file
          if (self.requireSender && (self.file || self.container_base) && self.Afzender === '') {
            return false;
          }
          break;
        }
        default: {
          break;
        }
      }
      return true;
    };

    return {
      afterCreate() {
        self.originalAnwser = self.Antwoord;
        self.originalSender = self.Afzender;
        self.originalFileContent = self.container_base;

        if (self.type === 'meerkeuze') {
          self.originalAnwser = self.multipleChoicesAnswer;
        }
      },

      setOpened: flow(function*() {
        if (self.Geopend != 1) {
          yield client.post(`/api/questions/${self.recordID}/opened/`);
          self.Geopend = 1;
        }
      }),

      setSubmitted() {
        self.file = null;
        self.Respons = 1;
        self.validateStatus = ValidateStatus.PENDING;

        self.afterCreate();
      },

      setAnswer(val) {
        // do answer
        self.Antwoord = val;
      },

      setFile(file) {
        self.file = file;
        if (file === null) {
          self.container_base = '';
          self.validate();
        }
      },

      setSender(sender) {
        self.Afzender = sender;
      },

      validate() {
        const result = validate();
        self.validateStatus = result ? ValidateStatus.OK : ValidateStatus.FAILED;
        return result;
      },
    };
  });

export default Question;
