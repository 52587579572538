import React, { Suspense, lazy } from 'react';
import { Route, Switch } from 'react-router-dom';
import Footer from './components/Footer';
import LoadingScreen from './components/LoadingScreen';

const SetResult = lazy(() => import('./components/SetResult'));
const OptInSuccess = lazy(() => import('./components/Subscription/OptInSuccess'));
const Subscription = lazy(() => import('./components/Subscription/Subscription'));
const Wizard = lazy(() => import('./components/SetWizard/Wizard'));

function App() {
  return (
    <React.Fragment>
      <div id="content">
        <Suspense fallback={<LoadingScreen isLoading />}>
          <Switch>
            <Route exact path="/optin" component={Subscription} />
            <Route exact path="/optin-success" component={OptInSuccess} />

            <Route exact path="/vragen" component={Wizard} />
            <Route exact path="/resultaten" component={SetResult} />
            <Route
              render={props => <LoadingScreen {...props} isLoading={false} message={null} />}
            />
          </Switch>
        </Suspense>
      </div>
      <Footer />
    </React.Fragment>
  );
}

export default App;
