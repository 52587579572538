import { types } from 'mobx-state-tree';
import Question from '@/models/Question';

export const Result = types.model({
  id: types.identifier,
  html: types.maybe(types.string),
  type: types.string,
  question: types.reference(Question),
});

export const parseData = obj => ({
  id: obj.recordId,
  html: obj['Results_all::HTML'],
  type: obj['results_all_VRAGEN::Vraag_Type'],
  question: obj['Results_all::id_vraag'],
});
