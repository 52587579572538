import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router';
import { connectReduxDevtools } from 'mst-middlewares';
import { Provider } from 'mobx-react';
import App from './App.jsx';
import { Set } from './models/Set.js';
import { Subscription } from './models/Subscription.js';
import navigator from './navigator';
import ResultStore from './models/Results/store.js';

const remotedev = require('remotedev');

const env = { navigator };

const stores = {
  set: Set.create({ questions: [] }, env),
  subscription: Subscription.create({}, env),
  results: ResultStore.create({}, env),
};

// connect devtools
if (process.env.NODE_ENV === 'development') {
  Object.keys(stores).forEach(key => {
    connectReduxDevtools(remotedev, stores[key]);
  });

  window.stores = stores; // for playing around with the console
}

ReactDOM.render(
  <Provider {...stores}>
    <Router history={navigator}>
      <App />
    </Router>
  </Provider>,
  document.getElementById('root'),
);
